import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('reelsplayer', ({id, jwpMediaId}) => ({
  id: id,
  source: '',
  API_URL: `https://cdn.jwplayer.com/v2/media/${ jwpMediaId }?format=json`,
  muted: true,
  progress: 0,
  open: false,
  forcePlayTimeout: null,
  rejectPlayCount: 0,
  player: null,
  init () {
    if (window.reels_players === undefined) window.reels_players = [];
    window.reels_players.push(this);
    if (window.reels_player_muted === undefined) {
      window.reels_player_muted = true;
    }
  },
  async loadMP4 () {
    if (this.source) return;
    console.log('loadMP4', this.id);
    const w = this.$refs.video.offsetWidth;
    const response = await fetch(this.API_URL);
    const json = await response.json();
    if (response.status === 200) {
      let availableMP4Sources = json.playlist[0].sources.filter(o => (o.type === 'video/mp4'));
      let i = 0;
      while (!this.source && i < availableMP4Sources.length) {
        let candidate = availableMP4Sources[i];
        if (candidate.width >= w || i == availableMP4Sources.length - 1) {
          this.$refs.video.src = candidate.file;
          this.$refs.video.load();
        }
        i++;
      }
    }
  },
  exclusivePlay () {
    this.rejectPlayCount = 0;
    console.log('exclusivePlay', this.id);
    window.reel_playing = this.id;
    this.$dispatch('reels_pause');
    this.play();
    this.forcePlay();
  },
  forcePlay() {
    if (this.id == window.reel_playing) {
      const video = this.$refs.video;
      const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
      console.log({
        'isVideoPlaying': isVideoPlaying,
        'video.currentTime': video.currentTime,
        'video.paused': video.paused,
        'video.ended': video.ended,
        'video.readyState': video.readyState,
      });
      if (!isVideoPlaying) {
        this.$refs.video.play().catch((err) => {
          console.log('forcePlay error', err);
          this.rejectPlayCount++;
        });
        if (this.rejectPlayCount < 10) {
          this.forcePlayTimeout = setTimeout(this.forcePlay.bind(this), 250);
        }
      }
    }
  },
  exclusivePause () {
    if (this.id != window.reel_playing) {
      console.log('exclusivePause', this.id);
      clearTimeout(self.forcePlayTimeout);
      this.pause();
    }
  },
  play () {
    console.log('play', this.id);
    this.$refs.video.play();
    this.$refs.playButton.classList.add("hidden");
  },
  onLoadedMetaData () {
    console.log('onLoadedMetaData');
  },
  onLoadedData () {
    console.log('onLoadedData');
  },
  onPlay () {
    console.log('onPlay');
  },
  pause () {
    console.log('pause', this.id);
    this.$refs.video.pause();
    this.$refs.playButton.classList.remove("hidden");
  },
  togglePlayPause () {
    console.log('togglePause', this.id);
    if (this.$refs.video.paused) {
      this.play();
    } else {
      this.pause();
    }
  },
  mute () {
    console.log('mute', this.id);
    this.muted = true;
    this.$refs.video.muted = this.muted;
  },
  unmute () {
    this.muted = false;
    this.$refs.video.muted = this.muted;
    this.$refs.video.volume = 1;
  },
  toggleMute () {
    console.log('toggleMute', this.id);
    if (window.reels_player_muted) {
      window.reels_players.forEach(player => {
        player.unmute();
      });
    } else {
      window.reels_players.forEach(player => {
        player.mute();
      });
    }
    window.reels_player_muted = !window.reels_player_muted;
  },
  onTimeUpdate () {
    this.progress = this.$refs.video.currentTime / this.$refs.video.duration;
  },
}));
